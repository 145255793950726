import React, { useState, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { FeedbackContext } from '../context/FeedbackContext';
import { format, isToday, isYesterday, subDays } from 'date-fns';
import { Calendar, List, User } from 'lucide-react';

const DashboardContainer = styled.div`
  padding: 2rem;
  background-color: #ffffff;
  min-height: 100vh;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  background-color: #10b981;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

const DashboardTitle = styled.h1`
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: 600;
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const StatCard = styled.div`
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

const StatIcon = styled.div`
  margin-right: 1rem;
  padding: 0.75rem;
  border-radius: 50%;
  background-color: #d1fae5;
`;

const StatContent = styled.div``;

const StatTitle = styled.h3`
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0;
  font-weight: 500;
`;

const StatValue = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin: 0;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${props => props.active ? '#10b981' : '#ffffff'};
  color: ${props => props.active ? '#ffffff' : '#111827'};
  border: 1px solid #10b981;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 500;

  &:hover {
    background-color: ${props => props.active ? '#059669' : '#d1fae5'};
  }
`;

const FeedbackList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FeedbackItem = styled.li`
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-left: 4px solid #10b981;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateX(5px);
  }
`;

const FeedbackHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const FeedbackName = styled.h3`
  font-size: 1rem;
  color: #111827;
  margin: 0;
  font-weight: 600;
`;

const FeedbackDate = styled.span`
  font-size: 0.875rem;
  color: #6b7280;
`;

const FeedbackContent = styled.p`
  font-size: 0.875rem;
  color: #374151;
  margin: 0;
  line-height: 1.5;
`;

const Dashboard = () => {
  const { feedbacks } = useContext(FeedbackContext);
  const [filter, setFilter] = useState('all');

  const filteredFeedbacks = useMemo(() => {
    const today = new Date();
    switch (filter) {
      case 'today':
        return feedbacks.filter(feedback => isToday(new Date(feedback.date)));
      case 'yesterday':
        return feedbacks.filter(feedback => isYesterday(new Date(feedback.date)));
      case 'last7days':
        const sevenDaysAgo = subDays(today, 7);
        return feedbacks.filter(feedback => new Date(feedback.date) >= sevenDaysAgo);
      default:
        return feedbacks;
    }
  }, [feedbacks, filter]);

  return (
    <DashboardContainer>
      <Header>
        <DashboardTitle>Dashboard de Feedbacks</DashboardTitle>
      </Header>

      <StatsContainer>
        <StatCard>
          <StatIcon>
            <List size={24} color="#10b981" />
          </StatIcon>
          <StatContent>
            <StatTitle>Total de Feedbacks</StatTitle>
            <StatValue>{feedbacks.length}</StatValue>
          </StatContent>
        </StatCard>
        <StatCard>
          <StatIcon>
            <Calendar size={24} color="#10b981" />
          </StatIcon>
          <StatContent>
            <StatTitle>Feedbacks Hoje</StatTitle>
            <StatValue>{feedbacks.filter(feedback => isToday(new Date(feedback.date))).length}</StatValue>
          </StatContent>
        </StatCard>
        <StatCard>
          <StatIcon>
            <User size={24} color="#10b981" />
          </StatIcon>
          <StatContent>
            <StatTitle>Nutricionistas</StatTitle>
            <StatValue>{new Set(feedbacks.map(f => f.nutritionist)).size}</StatValue>
          </StatContent>
        </StatCard>
      </StatsContainer>

      <FilterContainer>
        <FilterButton active={filter === 'all'} onClick={() => setFilter('all')}>Todos</FilterButton>
        <FilterButton active={filter === 'today'} onClick={() => setFilter('today')}>Hoje</FilterButton>
        <FilterButton active={filter === 'yesterday'} onClick={() => setFilter('yesterday')}>Ontem</FilterButton>
        <FilterButton active={filter === 'last7days'} onClick={() => setFilter('last7days')}>Últimos 7 dias</FilterButton>
      </FilterContainer>

      <FeedbackList>
        {filteredFeedbacks.map((feedback, index) => (
          <FeedbackItem key={index}>
            <FeedbackHeader>
              <FeedbackName>{feedback.name} - {feedback.nutritionist}</FeedbackName>
              <FeedbackDate>{format(new Date(feedback.date), 'dd/MM/yyyy HH:mm')}</FeedbackDate>
            </FeedbackHeader>
            <FeedbackContent>{feedback.feedback}</FeedbackContent>
          </FeedbackItem>
        ))}
      </FeedbackList>
    </DashboardContainer>
  );
};

export default Dashboard;