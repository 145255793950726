import React from 'react';
import styled from 'styled-components';
import FeedbackForm from '../components/FeedbackForm';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
`;

const Home = () => {
  return (
    <Container>
      <Title>Nutri Inteligente - Feedback</Title>
      <FeedbackForm />
    </Container>
  );
};

export default Home;