import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { FeedbackProvider } from './context/FeedbackContext';
import AppRoutes from './routes/AppRoutes';

function App() {
  return (
    <AuthProvider>
      <FeedbackProvider>
        <Router>
          <AppRoutes />
        </Router>
      </FeedbackProvider>
    </AuthProvider>
  );
}

export default App;