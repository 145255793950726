import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { FeedbackContext } from '../context/FeedbackContext'
import NutritionistSelector from './NutritionistSelector'
import { Send, CheckCircle, X } from 'lucide-react'

const FormContainer = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 500px;
  margin: 2rem auto;
  position: relative;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const InputGroup = styled.div`
  margin-bottom: 1.5rem;
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
`

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s ease;

  &:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.1);
  }
`

const TextArea = styled(Input).attrs({ as: 'textarea' })`
  height: 120px;
  resize: vertical;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #45a049;
  }

  svg {
    margin-left: 0.5rem;
  }
`

const Message = styled.div`
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 1rem;
  font-weight: 500;
`

const ErrorMessage = styled(Message)`
  background-color: #FEE2E2;
  color: #DC2626;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const SuccessCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 400px;
  width: 90%;
  text-align: center;
  position: relative;
`

const SuccessIcon = styled(CheckCircle)`
  color: #4CAF50;
  margin-bottom: 1rem;
`

const SuccessText = styled.p`
  color: #333;
  font-weight: 600;
  margin: 0 0 1rem 0;
`

const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  
  &:hover {
    color: #333;
  }
`

export default function Component() {
  const [nutritionist, setNutritionist] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [feedback, setFeedback] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const { addFeedback } = useContext(FeedbackContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    setSuccess('')

    if (!nutritionist || !name || !phone || !feedback) {
      setError('Por favor, preencha todos os campos.')
      return
    }

    try {
      await addFeedback({ nutritionist, name, phone, feedback })
      setNutritionist('')
      setName('')
      setPhone('')
      setFeedback('')
      setSuccess('Feedback enviado com sucesso!')
    } catch (error) {
      console.error('Erro ao enviar feedback:', error)
      setError('Ocorreu um erro ao enviar o feedback. Por favor, tente novamente.')
    }
  }

  const handleCloseSuccess = () => {
    setSuccess('')
  }

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit}>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <InputGroup>
          <Label htmlFor="nutritionist">Nutricionista</Label>
          <NutritionistSelector
            id="nutritionist"
            value={nutritionist}
            onChange={(e) => setNutritionist(e.target.value)}
          />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="name">Seu nome</Label>
          <Input
            id="name"
            type="text"
            placeholder="Digite seu nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="phone">Seu telefone</Label>
          <Input
            id="phone"
            type="tel"
            placeholder="Digite seu telefone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="feedback">Seu feedback</Label>
          <TextArea
            id="feedback"
            placeholder="Digite seu feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            required
          />
        </InputGroup>
        <Button type="submit">
          Enviar Feedback
          <Send size={18} />
        </Button>
      </Form>
      {success && (
        <Overlay>
          <SuccessCard>
            <CloseButton onClick={handleCloseSuccess}>
              <X size={24} />
            </CloseButton>
            <SuccessIcon size={48} />
            <SuccessText>{success}</SuccessText>
            <Button onClick={handleCloseSuccess}>Fechar</Button>
          </SuccessCard>
        </Overlay>
      )}
    </FormContainer>
  )
}