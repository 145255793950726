import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyA163Z-n0nlsuz0KRB6NAZq_6PEVfu0Rz8",
    authDomain: "feedbacks-f3303.firebaseapp.com",
    projectId: "feedbacks-f3303",
    storageBucket: "feedbacks-f3303.appspot.com",
    messagingSenderId: "1031648338631",
    appId: "1:1031648338631:web:42f021009524b0777994e2",
    measurementId: "G-LK908YGEBH"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);

export { db, auth };
console.log('Firebase inicializado');


