import React, { createContext, useEffect, useState, useCallback } from 'react';
import { ref, push, onValue, getDatabase } from 'firebase/database';
import { auth } from '../utils/firebase';

export const FeedbackContext = createContext(null);

export const FeedbackProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [feedbacks, setFeedbacks] = useState([]);
  const [todayFeedbacks, setTodayFeedbacks] = useState([]);
  const [totalFeedbacks, setTotalFeedbacks] = useState(0);

  useEffect(() => {
    const db = getDatabase();
    const feedbackRef = ref(db, 'feedbacks');

    const unsubscribe = onValue(feedbackRef, (snapshot) => {
      if (auth.currentUser && auth.currentUser.email === 'jaltair744@gmail.com') {
        const data = snapshot.val();
        if (data) {
          const currentDate = new Date();
          const feedbackArray = Object.entries(data).map(([key, value]) => ({
            id: key,
            ...value,
            date: new Date(value.date)
          }));

          setFeedbacks(feedbackArray);
          setTodayFeedbacks(feedbackArray.filter(item => 
            item.date.toDateString() === currentDate.toDateString()
          ));
          setTotalFeedbacks(feedbackArray.length);
        } else {
          setFeedbacks([]);
          setTodayFeedbacks([]);
          setTotalFeedbacks(0);
        }
      }
      setLoading(false);
    }, (error) => {
      console.error("Error fetching feedbacks:", error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const addFeedback = useCallback(async (feedbackData) => {
    const db = getDatabase();
    const feedbackRef = ref(db, 'feedbacks');
    const newFeedback = {
      ...feedbackData,
      date: new Date().toISOString()
    };

    try {
      await push(feedbackRef, newFeedback);
      console.log(`Added feedback for: ${feedbackData.name}, Date: ${newFeedback.date}`);
    } catch (error) {
      console.error("Error adding feedback: ", error);
      throw error;
    }
  }, []);

  const value = {
    feedbacks,
    todayFeedbacks,
    totalFeedbacks,
    addFeedback
  };

  return (
    <FeedbackContext.Provider value={value}>
      {!loading && children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackProvider;