import React from 'react';
import styled from 'styled-components';
import { ChevronDown } from 'lucide-react';

const SelectWrapper = styled.div`
  position: relative;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  padding-right: 2.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 1rem;
  appearance: none;
  background-color: white;
  transition: border-color 0.2s ease;

  &:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.1);
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  pointer-events: none;
`;

const NutritionistSelector = ({ id, value, onChange }) => {
  return (
    <SelectWrapper>
      <Select id={id} value={value} onChange={onChange}>
        <option value="">Selecione a nutricionista</option>
        <option value="Eduarda Costa">Eduarda Costa</option>
        <option value="Eduarda Lemos">Eduarda Lemos</option>
      </Select>
      <IconWrapper>
        <ChevronDown size={18} color="#4A5568" />
      </IconWrapper>
    </SelectWrapper>
  );
};

export default NutritionistSelector;